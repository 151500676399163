/**
 * Student Profile JS
 *
 * @package LifterLMS_Social_Learning/Scripts
 *
 * @since 1.0.0
 * @version 1.1.0
 */

( function( $ ){
	/**
	 * UI & UX for Student Profiles
	 *
	 * @since 1.0.0
	 * @since 1.1.0 Unknown.
	 */
	var Profile = function() {

		var $modal = $( '#llms-sl-upload-modal' );

		/**
		 * Bind DOM events
		 *
		 * @since 1.0.0
		 * @since 1.1.0 Unknown.
		 *
		 * @return void
		 */
		function bind() {

			$modal.iziModal();

			$( '#llms-sl-upload-cover' ).on( 'click', function( e ) {
				$modal.iziModal( 'open' );
				$( '#llms-sl-uploader-zone' ).attr( 'data-upload-type', 'cover' );
				$( '#llms-sl-upload-type' ).val( 'cover' );
			} );

			$( '#llms-sl-upload-avatar' ).on( 'click', function( e ) {
				$modal.iziModal( 'open' );
				$( '#llms-sl-uploader-zone' ).attr( 'data-upload-type', 'avatar' );
				$( '#llms-sl-upload-type' ).val( 'avatar' );
			} );

			window.ondragover = function( e ) {
				e.preventDefault();
			};

			window.ondrop = onDrop;

			$( '#llms-sl-uploader-zone' ).on( 'dragover', function() {
				$( this ).addClass( 'dragover' );
			} ).on( 'dragout', function() {
				$( this ).removeClass( 'dragover' );
			} );

			$( '#llms-sl-upload-image' ).on( 'change', onUploadChange );

		};

		/**
		 * Handle drop events to allow dragging images into uploader
		 *
		 * @since 1.0.0
		 *
		 * @param obj e JS Event object.
		 * @return   void
		 */
		function onDrop( e ) {

			e.preventDefault();

			var $target = $( e.target );

			if ( ! $target.closest( '.llms-sl-uploader' ).length ) {
				return;
			}

			upload( e.dataTransfer.files[0] );

		};

		/**
		 * Handle upload when files are added to the file uploader
		 *
		 * @since 1.0.0
		 *
		 * @param obj e JS Event object.
		 * @return void
		 */
		function onUploadChange( e ) {

			upload( e.target.files[0] );

		};

		/**
		 * Upload files via AJAX
		 *
		 * @since 1.0.0
		 * @since 1.1.0 Unknown.
		 *
		 * @param obj file JS file transfer object.
		 * @return void
		 */
		function upload( file ) {

			var $wrap = $( '#llms-sl-uploader-zone' ),
			type      = $( '#llms-sl-upload-type' ).val(),
			data      = new FormData();
			data.append( 'action', 'llms_sl_upload_image' );
			data.append( 'image', file );
			data.append( 'nonce', $( '#llms-sl-upload-nonce' ).val() );
			data.append( 'type', type );

			LLMS.Ajax.call( {
				data: data,
				contentType: false,
				processData: false,
				beforeSend: function() {

					$wrap.find( '.llms-sl-error' ).remove();
					LLMS.Spinner.start( $wrap );

				},
				error: function( xhr, status, error ) {

					var msg = '';

					if ( 'object' === typeof error && error.message ) {
						msg = error.message;
					} else {
						msg = error;
					}
					show_error( msg, status, error );
					LLMS.Spinner.stop( $wrap );

				},
				success: function( r ) {

					LLMS.Spinner.stop( $wrap );
					if ( r && r.success ) {
						if ( 'avatar' === type ) {
							$( '#llms-avatar-img' ).html( r.data.image );
						} else if ( 'cover' === type ) {
							$( '#llms-cover-img' ).html( r.data.image );
						}
						$modal.iziModal( 'close' );
					} else {
						show_error( r.message, 'error', r );
					}

				}

			} );

		};

		/**
		 * Show an uploader error message
		 *
		 * @since 1.1.0
		 * @since 1.1.0 Unknown.
		 *
		 * @param string msg    Error message string.
		 * @param string status Error status string.
		 * @param obj    data   Additional data to log to the console.
		 * @return void
		 */
		function show_error( msg, status, data ) {

			console.error( status, data );

			msg = LLMS.l10n.translate( 'Error' ) + ': ' + msg;

			$( '#llms-sl-uploader-zone' ).append( '<p class="llms-sl-error">' + msg + '</p>' );

		};

		// Go.
		bind();

		return {

			upload: upload,

		};

	};

	window.llms_sl         = window.llms_sl || {};
	window.llms_sl.profile = new Profile();

} )( jQuery );
